import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import { instance } from "./config";

export const primaryColor = instance?.theme?.palette.primary;
export const secondaryColor = instance?.theme?.palette.secondary;

// Override Mui's theme typings to include the new theme property
declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    RaMenuItemLink: {
      root: {
        borderLeft: React.CSSProperties["borderLeft"];
      };
      active: {
        borderLeft: React.CSSProperties["borderLeft"];
      };
    };
  }
  interface ThemeOptions {
    RaMenuItemLink: {
      root: {
        borderLeft: React.CSSProperties["borderLeft"];
      };
      active: {
        borderLeft: React.CSSProperties["borderLeft"];
      };
    };
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor || "#e2422e"
    },
    secondary: {
      main: secondaryColor || "#572345"
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: { default: "#fff" }
  },
  shape: {
    borderRadius: 10
  },
  RaMenuItemLink: {
    root: {
      borderLeft: "3px solid #fff"
    },
    active: {
      borderLeft: `3px solid ${primaryColor || "#e2422e"}`
    }
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none"
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box"
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: primaryColor || "#e2422e",
        boxShadow: "none"
      }
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit"
        }
      }
    },
    MuiAppBar: {
      colorSecondary: {
        border: "none"
      }
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5"
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7"
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        border: "none"
      }
    }
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true
    }
  }
});
