const env = typeof window === "undefined" ? process.env : (window as any).env;

export interface InstanceInfo {
  id: number;
  name: string;
  primary_color: string;
  secondary_color: string;
  website: string;
  email: string;
  enableBilling: boolean;
  location: string;
  financial_management: boolean;
  gtpay: boolean;
  inventory_management: boolean;
  google_maps_url: string;
  contact: string;
  icon: {
    small: string;
    medium: string;
    thumbnail: string;
  };
  logo: {
    small: string;
    medium: string;
    thumbnail: string;
  };
}

export const backendUrl = env.BACKEND_URL || "http://localhost:5006";
export const flutterwavePublicKey = env.FLUTTERWAVE_PUBLIC_KEY;
export const nodeEnv = env.NODE_ENV;
export const port = env.PORT;
export const portalUrl = env.PORTAL_URL || "http://localhost:3000";
// tslint:disable-next-line:no-var-requires
export const instance = JSON.parse(env.INSTANCE || "{}");
export const googleAnalyticsMeasurementId = env.GOOGLE_ANALYTICS_MEASUREMENT_ID;
