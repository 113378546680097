import { instance } from "../config";

export const installGATag = (
  trackingId: string,
  additionalConfigInfo?: any
) => {
  const scriptId = "ga-gtag";

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  window.dataLayer = window.dataLayer || [];

  gtag("js", new Date());
  gtag("config", trackingId, additionalConfigInfo);
  gtag("set", "user_properties", {
    user_lab: instance?.company?.name
  });
  gtag("event", "page_view", {
    page_title: location.pathname,
    page_location: location.href
  });
};

export const gtag = function(...args: any) {
  window.dataLayer.push(args);
};
