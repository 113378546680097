import * as Sentry from "@sentry/nextjs";

const env = typeof window === "undefined" ? process.env : window.env;

const sentryDsn = env.SENTRY_DSN;

Sentry.init({
  dsn: sentryDsn,
  tracesSampleRate: 0.3
});
