import React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "../theme";
import { gtag, installGATag } from "../components/google-analytics";
import router from "next/router";
import { googleAnalyticsMeasurementId, instance } from "../config";

const App: React.FC<AppProps> = props => {
  const { Component, pageProps } = props;

  React.useEffect(() => {
    installGATag(googleAnalyticsMeasurementId);
  }, []);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  React.useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const handleRouteChange = () => {
    gtag("set", "user_properties", {
      user_lab: instance?.company?.name
    });

    gtag("event", "page_view", {
      page_title: location.pathname,
      page_location: location.href
    });
  };

  return (
    <>
      <Head>
        <title>My page</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
};

export default App;
